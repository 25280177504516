.article-text {
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin-right: 0;
  }

  * {
    font-size: 1.07rem;
    color: rgba(34, 34, 34, 1);
  }

  a {
    color: #77a72c;
    font-weight: 600;
    word-wrap: break-word;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    height: auto !important;
    display: inline;
  }

  figure.image {
    margin-bottom: 1rem;

    img {
      width: 100%;
    }

    figcaption {
      font-size: 0.875rem;
      margin-top: 0.5rem;
      color: rgb(170, 170, 170);
      display: block;
      text-align: right;
    }
  }

  div.image-with-source {
    margin-bottom: 1rem;

    img {
      margin-bottom: 0.25em;
    }

    span {
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }
  }

  p {
    margin-bottom: 1em;
  }

  blockquote {
    max-width: 550px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &:before {
      width: 23px;
      height: 23px;
      content: '';
      background: no-repeat url(/quote.svg);
      background-size: contain;
      display: inline-block;
      position: relative;
      left: -2px;
      bottom: -4px;
    }
    p {
      font-size: 1.45rem;
      font-weight: 700;
      display: inline;
    }
  }

  table {
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
  }

  figure.table:not(.no-style) {
    tr {
      td,
      th {
        background-color: #eeeeee;
      }
    }
  }
}
